<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        sm="2"
      >
        <v-select
          dense
          v-model="httpRequest.M.method.S"
          :items="endpointEnum.method"
          label="Methods *"
          required
          :rules="[fieldRequire]"
        ></v-select>
      </v-col>
      <v-col
        cols="12"
        sm="10"
      >
        <v-text-field
          dense
          v-model="httpRequest.M.url.S"
          label="URL *"
          required
          :rules="[fieldRequire]"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="2"
      >
        <div class="subtitle-1 text--secondary">Headers</div>
      </v-col>
      <v-col
        cols="12"
        sm="10"
      >
        <v-row
          class="mt-n4"
          v-for="(value, key, headerIndex) in httpRequest.M.headers.M"
          :key="headerIndex"
        >
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              dense
              :value="key"
              label="Key"
              required
              :rules="[fieldRequire]"
              @blur="httpRequest.M.headers.M = editObjectKey(httpRequest.M.headers.M, $event.target.value, key)"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              dense
              :value="value.S"
              label="Value"
              required
              :rules="[fieldRequire]"
              @blur="httpRequest.M.headers.M = editObjectValue(httpRequest.M.headers.M, key, $event.target.value)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row
          class="mt-n4"
        >
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              dense
              label="Key"
              v-model="objectKey"
              @blur="httpRequest.M.headers.M = addNewObjectKey(httpRequest.M.headers.M, $event.target.value)"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              dense
              label="Value"
              v-model="objectValue"
              @blur="httpRequest.M.headers.M = addNewObjectValue(httpRequest.M.headers.M, $event.target.value)"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        sm="2"
      >
        <div class="subtitle-1 text--secondary">Params</div>
      </v-col>
      <v-col
        cols="12"
        sm="10"
      >
        <v-row
          class="mt-n4"
          v-for="(value, key, paramIndex) in httpRequest.M.params.M"
          :key="paramIndex"
        >
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              dense
              :value="key"
              label="Key"
              required
              :rules="[fieldRequire]"
              @blur="httpRequest.M.params.M = editObjectKey(httpRequest.M.params.M, $event.target.value, key)"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              dense
              :value="value.S"
              label="Value"
              required
              :rules="[fieldRequire]"
              @blur="httpRequest.M.params.M = editObjectValue(httpRequest.M.params.M, key, $event.target.value)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row
          class="mt-n4"
        >
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              dense
              label="Key"
              @blur="httpRequest.M.params.M = addNewObjectKey(httpRequest.M.params.M, $event.target.value)"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              dense
              label="Value"
              @blur="httpRequest.M.params.M = addNewObjectValue(httpRequest.M.params.M, $event.target.value)"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        sm="2"
      >
        <div class="subtitle-1 text--secondary">Data</div>
      </v-col>
      <v-col
        cols="12"
        sm="10"
      >
        <v-select
          class="text-capitalize"
          dense
          v-model="httpRequest.M.data.type"
          :items="endpointEnum.data"
          @change="httpRequest.M.data.type === 'string' ? Object.assign(httpRequest.M.data, { S: null }, delete httpRequest.M.data.JS) : Object.assign(httpRequest.M.data, { JS: {} }, delete httpRequest.M.data.S)"
        ></v-select>
      </v-col>
      <v-col
        class="mt-n6"
        cols="12"
      >
        <template v-if="httpRequest.M.data.type === 'string'">
          <v-textarea
            v-model="httpRequest.M.data.S"
          >
          </v-textarea>
        </template>
        <template v-else>
          <msnl-editor :min-line="5" :max-line="10" :value="httpRequest.M.data.JS || {M: '{}'}" @input="httpRequest.M.data.JS = $event"></msnl-editor>
          <!-- <v-jsoneditor v-model="httpRequest.M.data.value" :options="{ mode: 'code' }" :plus="false" height="400px" @error="onError"></v-jsoneditor> -->
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import endpointTemplateForm from '@/template/endpointTemplate.json'

export default {
  name: 'ProcessorHttpRequestForm',
  model: {
    prop: 'httpRequest'
  },
  components: {
    'msnl-editor': () => import('@/components/MSNLEditor.vue')
  },
  props: {
    httpRequest: {
      type: Object,
      default () {
        return {
        }
      },
      required: true
    }
  },
  data: () => ({
    objectKey: null,
    objectValue: null
  }),
  computed: {
    endpointEnum: function () {
      return endpointTemplateForm.enum.httpRequest
    },
    fieldRequire: function () {
      return v => !!v || 'field is required'
    },
    counterRequire: function (lessLength, moreLength) {
      if (lessLength && moreLength) {
        return v => ((v || '').length >= lessLength && (v || '').length <= moreLength) || `field must be ${lessLength}-${moreLength} characters`
      } else {
        return v => (v || '').length <= lessLength || `field must be ${lessLength} characters`
      }
    }
  },
  methods: {
    editObjectKey: function (object, newKey, oldKey) {
      let newObject = Object.assign({}, object)
      if (newKey !== oldKey) {
        if (!newKey && !newObject[oldKey].S) {
          this.$delete(newObject, oldKey)
        } else {
          newObject = {}
          for (const [key, value] of Object.entries(object)) {
            if (key === oldKey) {
              newObject[newKey] = value
            } else {
              newObject[key] = value
            }
          }
        }
      }
      return newObject
    },
    editObjectValue: function (object, key, value) {
      let newObject = Object.assign({}, object)
      if (!key && !value) {
        this.$delete(newObject, key)
      } else {
        newObject[key] = { S: value }
      }
      return newObject
    },
    addNewObjectKey: function (object, key) {
      let newObject = Object.assign({}, object)
      if (key) {
        this.$set(newObject, key, { S: '' })
      }
      this.objectKey = null
      return newObject
    },
    addNewObjectValue: function (object, value) {
      let newObject = Object.assign({}, object)
      if (value) {
        this.$set(newObject, `key-${Object.keys(newObject).length}`, { S: value })
      }
      this.objectValue = null
      return newObject
    },
    setValue (value) {
      this.httpRequest.M.data.JS = value
      // console.log(value)
      // let val = this.value
      // val.M.value = value
      // this.$emit('input', val)
    },
    ttt (val) {
      console.log(val)
    }
  }
}
</script>
